<template>
  <div>
    <!-- Page content -->
    <b-container>
      <b-row class="justify-content-center app-local-justify-center">
        <b-col cols="12">
          <b-card no-body class="bg-white border-0 mb-0">

            <b-row>

              <b-col class="cols-xs-12 cols-sm-6">
                <b-card-body class="px-lg-4 py-lg-4">
                  <div class="text-left text-muted mb-2">
                    <img src="@/assets/images/logo/text-logo.svg" class='app-local-image-logo py-2 pl-3' height="120px" alt="HappyOne" />                
                  </div>
                  <ValidationObserver ref="form">
                  <div>
                    <app-input id="email" v-model="email" :label="trans('email',283)" validatorRules="required|email" class="mb-4" @enter="login" :currentError.sync="is_error" :validatorName="trans('email',283)" />

                    <app-password id="pass" v-model="password" :validatorName="trans('auth-password',278)" validatorRules="required" @enter="login" :currentError.sync="is_error" :label="trans('auth-password',278)"/>
                    <ValidationObserver ref="custom">
                      <app-input v-model="error" type="hidden" validatorRules="required" :validatorCustomMessage="{ required: current_error }"/>
                    </ValidationObserver>

                    <b-col class="text-right p-0 mt-1">
                      <router-link to="/forget-password"><small>{{trans('forgot_password',277)}}</small></router-link>
                    </b-col>

                  </div>
                  </ValidationObserver>
                  <div class="text-center">
                      <app-button type="primary" class="my-4" :loading="loading" :block="true" size="md" @click="login">{{trans('login',284)}}</app-button>
                  </div>
                </b-card-body>
              </b-col>

              <b-col cols="6" class="d-none d-md-block">
                <b-card-body class="d-flex h-100 justify-content-center px-lg-4 py-lg-4">
                  <div class="d-flex align-items-center">
                    <img src="@/assets/images/logo/logo.svg" right height="250px" alt="HappyOne" />                
                  </div>
                </b-card-body>
              </b-col>

            </b-row>

          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>


import axios from "@axios";
import {ValidationObserver} from "vee-validate";
import AppPassword from "@core/components/AppPassword.vue"
import UserSettings from '@core/scripts/UserSettings';

  export default {
    
    components: {
        ValidationObserver,
        AppPassword
    },

    watch: {
        "email":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.is_error = false;
                  this.$refs.custom.validate();
                }
                
            }
        },
          "password":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.is_error = false;
                  this.$refs.custom.validate();
                }
                
            },
        }
    },

    data() {
      return {
        email: '',
        password: '',
        error: 'false',
        is_error: false,
        current_error: '',
        rememberMe: false,
        loading: false,
        userSettings: new UserSettings(),
      };
    },
    methods: {

      login() {
        
       console.log(process.env.VUE_APP_ENDPOINTS_URL);
        this.$refs.form.validate().then((result) => {
          if (!result) {
              return false;
          }else{
            this.loading = true;
          axios.post(
              "authorization/oauth/token", {
                      
                          grant_type: 'password',
                          client_id: 35,
                          scope: 'app-portal',
                          username: this.email,
                          password: this.password,
                                          
                  })
              .then((response) => {
                  localStorage.setItem('user-portal', JSON.stringify(response.data.user_data));
                  localStorage.setItem('userSettings', JSON.stringify(response.data.user_settings));
                  this.translations.changeDictionary(response.data.dictionary);
                  localStorage.setItem('dictionary_version', response.data.dictionary_version); 
                  localStorage.setItem('user_manual_link', response.data.user_manual_link);
                   this.$router.push(this.$route.query.redirect || '/');
              })
              .catch((error) => {
                console.log(error);
                  if(error.response.data.error == 'invalid_grant') {
                      this.current_error = this.trans('incorrect-data',281)
                      this.error = '';
                      this.is_error = true;
                      this.$refs.custom.validate();
                  }
                  else if(error.response.data.error == 'user_blocked'){
                      this.current_error = this.trans('account-blocked',281)
                      this.error = '';
                      this.is_error = true;
                      this.$refs.custom.validate();
                  }else{
                      console.error(this.trans('error-during-authentication',281) `${error}`);
                  }
                  
              }).finally(() => {
                        this.loading = false;
                    }); 
        }
        });
        }
      }
    };
</script>
<style scope>
.app-local-justify-center{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  min-height: calc(100vh - 64px);
}

.app-local-image-logo{
  margin-left: -20px;
}
</style>
